import React from "react";
import { GithubContributions } from "react-github-graph";

export default function App() {
  return (
    <div className="mx-auto mt-10">

    <GithubContributions username="shaantanu9"/>
    </div>
  );
}